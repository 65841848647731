export const SHOPIFY_DOMAIN = process.env.GATSBY_SHOPIFY_DOMAIN;
export const CHECKOUT_DOMAIN = process.env.GATSBY_CHECKOUT_DOMAIN;
export const SHOPIFY_STOREFRONT_TOKEN = process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN;
export const SITE_URL = process.env.GATSBY_SITE_URL;
export const SANITY_STUDIO_PROJECT_ID = process.env.GATSBY_SANITY_STUDIO_PROJECT_ID;
export const SANITY_STUDIO_DATASET = process.env.GATSBY_SANITY_STUDIO_DATASET;
export const KLAVIYO_COMPANY_ID = process.env.GATSBY_KLAVIYO_COMPANY_ID;
export const PRODUCTION_ENV = process.env.NODE_ENV === "production";
export const MALOMO_PUBLIC_KEY = process.env.GATSBY_MALOMO_PUBLIC_KEY;
export const REBUY_API_KEY = process.env.GATSBY_REBUY_API_KEY;

export const CART_ID = "cartId";
export const customerTokenKey = "access-token";
export const customerSessionKey = "mod-lighting-customer";
export const loyaltyLionAccessToken = "loyalty-lion-access-token";
export const rebuyUniqeId = "rebuyUUID";
export const DOTS = "...";
export const MAIL_ID_REGEX = /MailingAddress\/(\d+)/;
export const recentlyViewedKey = "recently-viewed";
export const countryCodeKey = "countryCode";
export const reviewsWidgetId = "reviews-widget";

export const PREDEFINED_MARKETS = {
	AU: {
		currency: "AUD",
		symbol: "$",
	},
	CA: {
		currency: "CAD",
		symbol: "$",
	},
	NZ: {
		currency: "NZD",
		symbol: "$",
	},
	GB: {
		currency: "GBP",
		symbol: "£",
	},
	US: {
		currency: "USD",
		symbol: "$",
	},
	DE: {
		currency: "EUR",
		symbol: "€",
	},
};

export const SWYM_DATA = "swymData";
export const RECOMMENDED_QUANTITIES = [1, 2, 4];

export const CURRENCY = "USD";

// media queries
export const SCREEN_SIZES = { sm: "640px", md: "768px", lg: "992px", xl: "1240px", xxl: "1560px", };

export const CUSTOMER_STATE = {
	Guest: "GUEST",
	Logged_in: "LOGGED_IN",
	Logged_out: "LOGGED_OUT",
};

export const sortOptions = {
	manual: "Featured",
	"best-selling": "Best selling",
	"title-ascending": "Alphabetically, A-Z",
	"title-descending": "Alphabetically, Z-A",
	"price-ascending": "Price, low to high",
	"price-descending": "Price, high to low",
	"created-ascending": "Date, old to new",
	"created-descending": "Date, new to old",
};

export const SEARCH_TABS = {
	PRODUCTS: "products",
	BLOGS: "blogs",
};

export const EUR_COUNTRIES = new Set([
	"AX", "AD", "AT", "BE", "CY", "EE", "FI", "FR", "GF", "TF", "DE", "GR", "GP", "IE", "IT", "XK", "LV", "LT", "LU", "MT",
	"MQ", "YT", "MC", "ME", "NL", "PT", "RE", "SM", "SK", "SI", "ES", "BL", "MF", "PM", "VA", "EU"
]);